// Reset Style file
// -------------------------------

* {
  outline: none;
  box-sizing: border-box;
}

html {
  line-height: 1;
  min-height: 100%;
  font-family: arial, sans-serif;
  text-size-adjust: (100%);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  border: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  &:hover,
  &:active {
    outline: 0;
    color: unset;
  }
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

ol,
ul {
  list-style: none;
}

table,
tbody,
tfoot,
thead,
tr,
th,
td {
  position: relative;
  vertical-align: middle;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

caption {
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
  font-weight: normal;
  vertical-align: middle;
}

q,
blockquote {
  quotes: none;
  &::before,
  &::after {
    content: "";
    content: none;
  }
}

a {
  img {
    border: none;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

i {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

svg:not(:root) {
  overflow: hidden;
}

pre {
  overflow: auto;
  white-space: normal;
  word-break: break-word;
  a {
    text-decoration: underline;
  }
  ul,
  ol {
    padding-left: 19.5px;
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul ul,
  ol ul {
    margin-left: 15px;
    list-style-type: circle;
    list-style-position: inside;
  }
  ol ol,
  ul ol {
    margin-left: 15px;
    list-style-position: inside;
    list-style-type: lower-latin;
  }
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
  line-height: normal;
  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  appearance: button;
  &[disabled] {
    cursor: default;
  }
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  margin: 4px
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
  height: auto;
  appearance: none;
}

input[type="search"] {
  // box-sizing: content-box;
  appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  appearance: none;
}