// Variable Fonts
// -------------------------------
// Text
$main-font-name: sans-serif !default;
$main-font-color: #3a3a3a !default;
$main-font-color--active: #000 !default;
$main-font-color--disabled: $color--disabled !default;
$main-font-boldSize:bold !default;
$main-font-lineHeight:normal !default;
// Icons
$main-iconFont-color: #2f679e !default;
$main-iconFont-name: 'dtIconFont-linear' !default;
$main-iconFont-color--disabled: $color--disabled !default;
// $font-path:'../fonts/iconFont/dtIconFont-linear/fonts';
