// Variable Template
// -------------------------------
$main-browserContext:16px !default;
$main-browserContext--calc:16px !default;
$main-space:10px !default;
$main-radius:2px !default;
$main-border:1px solid !default;
$main-box-shadow:0 1px 3px rgba(0, 0, 0, .15) !default;
// Transition
$main-transition--timing:.3s !default;
$main-transition--duration:linear !default;