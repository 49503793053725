.react-datepicker-wrapper{
  width: 100%;
  .react-datepicker__input-container{
    width: 100%;
    button{
      width: 100%;
    }
    i{
      position: absolute;
      right: 5px;
      top: 8px;
    }
  }
}