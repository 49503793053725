.licenseBar {
  width: 100%;
  text-align: center;
  font-weight: bold;

  &.active {
    color: $color--success
  }

  &.warning {
    color: $color--warning
  }

  &.expire{
    color: $color--error
  }
}