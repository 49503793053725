.nhsoTokenForm,
.columnForm {
  width: 100%;
  flex-wrap: nowrap;
  // align-items: flex-start;
  align-items: center;
  .dt-inputForm-block {
    flex: 1;
    &:first-child{
      margin-right: 5px;
    }
  }
  .btnAction {
    margin-left: rem($main-space);
    margin-top: rem(7px);
  }
}