.ui.fluid.dropdown{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px;
  border-color: #b8bfc5;
  border-radius: 2px;
  border-style: solid;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  height: 2.5rem;
  padding: 0.3125rem;
  transition: border 0.3s linear, box-shadow 0.3s linear;
}