// Button Control
.btnControl {
  align-items: center;
  display: flex;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  padding: 10px;

  .dt-btn {
    margin-right: rem($main-space);
  }

  &-left {
    margin-right: auto;
  }

  &-center {
    margin-right: auto;
    margin-left: auto;
  }

  &-right {
    margin-left: auto;
  }

  &.--footer {
    padding: rem($main-space);
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);
  }
}