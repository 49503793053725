.ico-attachFileHoriz:before {
  content: "\e929";
}

.ico-attachFileVert:before {
  content: "\e92a";
}

.ico-publish:before {
  content: "\e92b";
}

.ico-cloudUpload:before {
  content: "\e92c";
}

.ico-cloudOff:before {
  content: "\e92d";
}

.ico-cloudDownload:before {
  content: "\e92e";
}

.ico-cloudDone:before {
  content: "\e92f";
}

.ico-cloud:before {
  content: "\e930";
}

.ico-folderShared:before {
  content: "\e931";
}

.ico-foldeOpen:before {
  content: "\e932";
}

.ico-folder:before {
  content: "\e933";
}

.ico-createNewFolder:before {
  content: "\e934";
}

.ico-insertInvitation:before {
  content: "\e935";
}

.ico-wallpaper:before {
  content: "\e936";
}

.ico-sort:before {
  content: "\e937";
}

.ico-textFormat:before {
  content: "\e938";
}

.ico-unarchive:before {
  content: "\e939";
}

.ico-moveToInbox:before {
  content: "\e93a";
}

.ico-saveAlt:before {
  content: "\e93b";
}

.ico-save:before {
  content: "\e93c";
}

.ico-menu:before {
  content: "\e93d";
}

.ico-search:before {
  content: "\e93e";
}

.ico-wifiOff:before {
  content: "\e91d";
}

.ico-wifi:before {
  content: "\e91e";
}

.ico-power:before {
  content: "\e91f";
}

.ico-settingBox:before {
  content: "\e920";
}

.ico-settings:before {
  content: "\e921";
}

.ico-powerStart:before {
  content: "\e922";
}

.ico-bolt:before {
  content: "\e923";
}

.ico-people:before {
  content: "\e924";
}

.ico-swapVert:before {
  content: "\e925";
}

.ico-swapHoriz:before {
  content: "\e926";
}

.ico-swapVertCircle:before {
  content: "\e927";
}

.ico-swapHorizCircle:before {
  content: "\e928";
}

.ico-cancelCircle:before {
  content: "\e902";
}

.ico-arrowUp:before {
  content: "\e903";
}

.ico-arrowRight:before {
  content: "\e904";
}

.ico-arrowLeft:before {
  content: "\e905";
}

.ico-arrowDown:before {
  content: "\e906";
}

.ico-photo:before {
  content: "\e907";
}

.ico-pen:before {
  content: "\e908";
}

.ico-block:before {
  content: "\e909";
}

.ico-outline-clear-24px:before {
  content: "\e90a";
}

.ico-backspace:before {
  content: "\e90b";
}

.ico-addCircle:before {
  content: "\e90c";
}

.ico-addBox:before {
  content: "\e90d";
}

.ico-add:before {
  content: "\e90e";
}

.ico-accountBox:before {
  content: "\e90f";
}

.ico-renew:before {
  content: "\e910";
}

.ico-checkCircle:before {
  content: "\e911";
}

.ico-file:before {
  content: "\e912";
}

.ico-description:before {
  content: "\e913";
}

.ico-trashForever:before {
  content: "\e914";
}

.ico-trash:before {
  content: "\e915";
}

.ico-notifications:before {
  content: "\e916";
}

.ico-notificationsBlocking:before {
  content: "\e917";
}

.ico-exit:before {
  content: "\e918";
}

.ico-email:before {
  content: "\e919";
}

.ico-help:before {
  content: "\e91a";
}

.ico-info:before {
  content: "\e91b";
}

.ico-key:before {
  content: "\e91c";
}

.ico-lock:before {
  content: "\e900";
}

.ico-user:before {
  content: "\e901";
}