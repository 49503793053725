/** Base */
* {
  outline: none;
  box-sizing: border-box; }

html {
  line-height: 1;
  min-height: 100%;
  font-family: arial, sans-serif;
  text-size-adjust: 100%; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  border: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  background: transparent; }
  a:hover, a:active {
    outline: 0;
    color: unset; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

ol,
ul {
  list-style: none; }

table,
tbody,
tfoot,
thead,
tr,
th,
td {
  position: relative;
  vertical-align: middle; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

caption {
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
  font-weight: normal;
  vertical-align: middle; }

q,
blockquote {
  quotes: none; }
  q::before, q::after,
  blockquote::before,
  blockquote::after {
    content: "";
    content: none; }

a img {
  border: none; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block; }

i {
  font-style: italic; }

b,
strong {
  font-weight: bold; }

svg:not(:root) {
  overflow: hidden; }

pre {
  overflow: auto;
  white-space: normal;
  word-break: break-word; }
  pre a {
    text-decoration: underline; }
  pre ul,
  pre ol {
    padding-left: 19.5px; }
  pre ul {
    list-style-type: disc;
    list-style-position: inside; }
  pre ol {
    list-style-type: decimal;
    list-style-position: inside; }
  pre ul ul,
  pre ol ul {
    margin-left: 15px;
    list-style-type: circle;
    list-style-position: inside; }
  pre ol ol,
  pre ul ol {
    margin-left: 15px;
    list-style-position: inside;
    list-style-type: lower-latin; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
  line-height: normal; }
  button:-moz-focus-inner,
  input:-moz-focus-inner,
  optgroup:-moz-focus-inner,
  select:-moz-focus-inner,
  textarea:-moz-focus-inner {
    border: 0;
    padding: 0; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  appearance: button; }
  button[disabled],
  input[type="button"][disabled],
  input[type="reset"][disabled],
  input[type="submit"][disabled] {
    cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  margin: 4px; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
  height: auto;
  appearance: none; }

input[type="search"] {
  appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  appearance: none; }

/** Variables */
/** Fonts */
@font-face {
  font-family: 'dtIconFont-linear';
  src: url("../fonts/iconFont/dtIconFont-linear/fonts/dtIconFont-linear.eot?wcffy9");
  src: url("../fonts/iconFont/dtIconFont-linear/fonts/dtIconFont-linear.eot?wcffy9#iefix") format("embedded-opentype"), url("../fonts/iconFont/dtIconFont-linear/fonts/dtIconFont-linear.ttf?wcffy9") format("truetype"), url("../fonts/iconFont/dtIconFont-linear/fonts/dtIconFont-linear.woff?wcffy9") format("woff"), url("../fonts/iconFont/dtIconFont-linear/fonts/dtIconFont-linear.svg?wcffy9#dtIconFont-linear") format("svg");
  font-weight: normal;
  font-style: normal; }

.dtIconFont-linear,
i[class^="ico-"],
i[class*="ico-"],
[class^="ico-"]::before,
[class*="ico-"]::before,
[class^="icoAf-"]::after,
[class*="icoAf-"]::after {
  font-family: "dtIconFont-linear" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ico-attachFileHoriz:before {
  content: "\e929"; }

.ico-attachFileVert:before {
  content: "\e92a"; }

.ico-publish:before {
  content: "\e92b"; }

.ico-cloudUpload:before {
  content: "\e92c"; }

.ico-cloudOff:before {
  content: "\e92d"; }

.ico-cloudDownload:before {
  content: "\e92e"; }

.ico-cloudDone:before {
  content: "\e92f"; }

.ico-cloud:before {
  content: "\e930"; }

.ico-folderShared:before {
  content: "\e931"; }

.ico-foldeOpen:before {
  content: "\e932"; }

.ico-folder:before {
  content: "\e933"; }

.ico-createNewFolder:before {
  content: "\e934"; }

.ico-insertInvitation:before {
  content: "\e935"; }

.ico-wallpaper:before {
  content: "\e936"; }

.ico-sort:before {
  content: "\e937"; }

.ico-textFormat:before {
  content: "\e938"; }

.ico-unarchive:before {
  content: "\e939"; }

.ico-moveToInbox:before {
  content: "\e93a"; }

.ico-saveAlt:before {
  content: "\e93b"; }

.ico-save:before {
  content: "\e93c"; }

.ico-menu:before {
  content: "\e93d"; }

.ico-search:before {
  content: "\e93e"; }

.ico-wifiOff:before {
  content: "\e91d"; }

.ico-wifi:before {
  content: "\e91e"; }

.ico-power:before {
  content: "\e91f"; }

.ico-settingBox:before {
  content: "\e920"; }

.ico-settings:before {
  content: "\e921"; }

.ico-powerStart:before {
  content: "\e922"; }

.ico-bolt:before {
  content: "\e923"; }

.ico-people:before {
  content: "\e924"; }

.ico-swapVert:before {
  content: "\e925"; }

.ico-swapHoriz:before {
  content: "\e926"; }

.ico-swapVertCircle:before {
  content: "\e927"; }

.ico-swapHorizCircle:before {
  content: "\e928"; }

.ico-cancelCircle:before {
  content: "\e902"; }

.ico-arrowUp:before {
  content: "\e903"; }

.ico-arrowRight:before {
  content: "\e904"; }

.ico-arrowLeft:before {
  content: "\e905"; }

.ico-arrowDown:before {
  content: "\e906"; }

.ico-photo:before {
  content: "\e907"; }

.ico-pen:before {
  content: "\e908"; }

.ico-block:before {
  content: "\e909"; }

.ico-outline-clear-24px:before {
  content: "\e90a"; }

.ico-backspace:before {
  content: "\e90b"; }

.ico-addCircle:before {
  content: "\e90c"; }

.ico-addBox:before {
  content: "\e90d"; }

.ico-add:before {
  content: "\e90e"; }

.ico-accountBox:before {
  content: "\e90f"; }

.ico-renew:before {
  content: "\e910"; }

.ico-checkCircle:before {
  content: "\e911"; }

.ico-file:before {
  content: "\e912"; }

.ico-description:before {
  content: "\e913"; }

.ico-trashForever:before {
  content: "\e914"; }

.ico-trash:before {
  content: "\e915"; }

.ico-notifications:before {
  content: "\e916"; }

.ico-notificationsBlocking:before {
  content: "\e917"; }

.ico-exit:before {
  content: "\e918"; }

.ico-email:before {
  content: "\e919"; }

.ico-help:before {
  content: "\e91a"; }

.ico-info:before {
  content: "\e91b"; }

.ico-key:before {
  content: "\e91c"; }

.ico-lock:before {
  content: "\e900"; }

.ico-user:before {
  content: "\e901"; }

.icoAf-attachFileHoriz:after {
  content: "\e929"; }

.icoAf-attachFileVert:after {
  content: "\e92a"; }

.icoAf-publish:after {
  content: "\e92b"; }

.icoAf-cloudUpload:after {
  content: "\e92c"; }

.icoAf-cloudOff:after {
  content: "\e92d"; }

.icoAf-cloudDownload:after {
  content: "\e92e"; }

.icoAf-cloudDone:after {
  content: "\e92f"; }

.icoAf-cloud:after {
  content: "\e930"; }

.icoAf-folderShared:after {
  content: "\e931"; }

.icoAf-foldeOpen:after {
  content: "\e932"; }

.icoAf-folder:after {
  content: "\e933"; }

.icoAf-createNewFolder:after {
  content: "\e934"; }

.icoAf-insertInvitation:after {
  content: "\e935"; }

.icoAf-wallpaper:after {
  content: "\e936"; }

.icoAf-sort:after {
  content: "\e937"; }

.icoAf-textFormat:after {
  content: "\e938"; }

.icoAf-unarchive:after {
  content: "\e939"; }

.icoAf-moveToInbox:after {
  content: "\e93a"; }

.icoAf-saveAlt:after {
  content: "\e93b"; }

.icoAf-save:after {
  content: "\e93c"; }

.icoAf-menu:after {
  content: "\e93d"; }

.icoAf-search:after {
  content: "\e93e"; }

.icoAf-wifiOff:after {
  content: "\e91d"; }

.icoAf-wifi:after {
  content: "\e91e"; }

.icoAf-power:after {
  content: "\e91f"; }

.icoAf-settingBox:after {
  content: "\e920"; }

.icoAf-settings:after {
  content: "\e921"; }

.icoAf-powerStart:after {
  content: "\e922"; }

.icoAf-bolt:after {
  content: "\e923"; }

.icoAf-people:after {
  content: "\e924"; }

.icoAf-swapVert:after {
  content: "\e925"; }

.icoAf-swapHoriz:after {
  content: "\e926"; }

.icoAf-swapVertCircle:after {
  content: "\e927"; }

.icoAf-swapHorizCircle:after {
  content: "\e928"; }

.icoAf-cancelCircle:after {
  content: "\e902"; }

.icoAf-arrowUp:after {
  content: "\e903"; }

.icoAf-arrowRight:after {
  content: "\e904"; }

.icoAf-arrowLeft:after {
  content: "\e905"; }

.icoAf-arrowDown:after {
  content: "\e906"; }

.icoAf-photo:after {
  content: "\e907"; }

.icoAf-pen:after {
  content: "\e908"; }

.icoAf-block:after {
  content: "\e909"; }

.icoAf-outline-clear-24px:after {
  content: "\e90a"; }

.icoAf-backspace:after {
  content: "\e90b"; }

.icoAf-addCircle:after {
  content: "\e90c"; }

.icoAf-addBox:after {
  content: "\e90d"; }

.icoAf-add:after {
  content: "\e90e"; }

.icoAf-accountBox:after {
  content: "\e90f"; }

.icoAf-renew:after {
  content: "\e910"; }

.icoAf-checkCircle:after {
  content: "\e911"; }

.icoAf-file:after {
  content: "\e912"; }

.icoAf-description:after {
  content: "\e913"; }

.icoAf-trashForever:after {
  content: "\e914"; }

.icoAf-trash:after {
  content: "\e915"; }

.icoAf-notifications:after {
  content: "\e916"; }

.icoAf-notificationsBlocking:after {
  content: "\e917"; }

.icoAf-exit:after {
  content: "\e918"; }

.icoAf-email:after {
  content: "\e919"; }

.icoAf-help:after {
  content: "\e91a"; }

.icoAf-info:after {
  content: "\e91b"; }

.icoAf-key:after {
  content: "\e91c"; }

.icoAf-lock:after {
  content: "\e900"; }

.icoAf-user:after {
  content: "\e901"; }

/** Vendors */
.dt-input {
  appearance: none;
  border: 1px;
  border-color: #b8bfc5;
  border-radius: 2px;
  border-style: solid;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  height: 2.5rem;
  padding: 0.3125rem;
  transition: border 0.3s linear, box-shadow 0.3s linear; }
  .dt-input:focus {
    border-color: #9db7d0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.dt-inputField {
  position: relative;
  display: inline-flex; }
  .dt-inputField[class^="ico-"]::before, .dt-inputField[class*="ico-"]::before {
    font-size: 1.2rem;
    left: 0.4375rem;
    position: absolute;
    top: 50%;
    margin-top: -0.6rem;
    color: #2f679e; }
  .dt-inputField[class^="ico-"] input, .dt-inputField[class*="ico-"] input {
    padding-left: 2rem;
    width: 100%; }

.dt-dateField {
  width: 8.125rem; }
  .dt-dateField input {
    width: 100%; }

.dt-input-fix {
  width: 100%; }

.dt-input-w99 {
  width: 99%; }

.dt-input-w100 {
  width: 100%; }

.dt-input-hidden {
  display: none; }

.dt-input-error {
  border-color: #ce5f54; }

.dt-btn {
  align-items: baseline;
  background: #f1f1f1;
  border-radius: 0.125rem;
  border: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), inset 0 -20px 0 rgba(0, 0, 0, 0.05);
  color: #000;
  display: inline-flex;
  font-size: initial;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem;
  text-align: center;
  min-width: 5rem;
  text-transform: capitalize;
  cursor: pointer; }
  .dt-btn[disabled], .dt-btn.disabled {
    background: whitesmoke;
    color: rgba(0, 0, 0, 0.35);
    box-shadow: none; }
    .dt-btn[disabled]::before, .dt-btn[disabled]::after, .dt-btn.disabled::before, .dt-btn.disabled::after {
      color: rgba(0, 0, 0, 0.35); }
  .dt-btn:active {
    box-shadow: 0 0px 0px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 rgba(255, 255, 255, 0), inset 0 1px 1px rgba(0, 0, 0, 0.1); }
  .dt-btn[class*="ico"]::before, .dt-btn[class*="ico"]::after {
    font-size: 1.4rem;
    color: #2f679e; }
  .dt-btn[class*="ico"]:not(.--iconOnly)::before {
    margin-right: 0.3125rem; }
  .dt-btn[class*="ico"]:not(.--iconOnly)::after {
    margin-left: 0.3125rem; }
  .dt-btn:hover {
    background-color: #d8d8d8; }
  .dt-btn.--iconOnly {
    min-width: initial;
    width: 2.5rem; }
    .dt-btn.--iconOnly[class*="ico"]::before, .dt-btn.--iconOnly[class*="ico"]::after {
      font-size: 1.5rem; }
  .dt-btn[class*="--bg-"] {
    color: #fff;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4); }
    .dt-btn[class*="--bg-"]::before, .dt-btn[class*="--bg-"]::after {
      color: #fff; }
  .dt-btn.--bg-primary {
    background-color: #3a7db4; }
    .dt-btn.--bg-primary:hover {
      background-color: #2e628d; }
  .dt-btn.--bg-success {
    background-color: #1ca131; }
    .dt-btn.--bg-success:hover {
      background-color: #147624; }
  .dt-btn.--bg-warning {
    background-color: #FFC107; }
    .dt-btn.--bg-warning:hover {
      background-color: #d39e00; }
  .dt-btn.--bg-error {
    background-color: #ce5f54; }
    .dt-btn.--bg-error:hover {
      background-color: #ba4135; }
  .dt-btn.--bg-link {
    background-color: #3a7db4; }

.dt-inputFile {
  align-items: baseline;
  background: #f1f1f1;
  border-radius: 0.125rem;
  border: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), inset 0 -20px 0 rgba(0, 0, 0, 0.05);
  color: #000;
  display: inline-flex;
  font-size: initial;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem;
  text-align: center;
  min-width: 5rem;
  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
  position: relative; }
  .dt-inputFile[disabled], .dt-inputFile.disabled {
    background: whitesmoke;
    color: rgba(0, 0, 0, 0.35);
    box-shadow: none; }
    .dt-inputFile[disabled]::before, .dt-inputFile[disabled]::after, .dt-inputFile.disabled::before, .dt-inputFile.disabled::after {
      color: rgba(0, 0, 0, 0.35); }
  .dt-inputFile:active {
    box-shadow: 0 0px 0px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 rgba(255, 255, 255, 0), inset 0 1px 1px rgba(0, 0, 0, 0.1); }
  .dt-inputFile input[type='file'] {
    cursor: pointer;
    height: 100px;
    opacity: 0;
    position: absolute;
    width: 100%; }
  .dt-inputFile .dt-inputFile-text {
    cursor: pointer; }

[class*="titleH"] {
  margin-bottom: 0.625rem; }

.titleH1 {
  font-size: 2rem; }

.titleH2 {
  font-size: 1.5rem; }

.titleH3 {
  font-size: 1.25rem; }

[data-type='radio'],
[data-type='checkbox'] {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 0.625rem;
  padding-right: 0.625rem;
  position: relative; }
  [data-type='radio'] > input[type='radio'], [data-type='radio'] > input[type='checkbox'],
  [data-type='checkbox'] > input[type='radio'],
  [data-type='checkbox'] > input[type='checkbox'] {
    appearance: none;
    height: 0;
    opacity: 0;
    width: 0; }
    [data-type='radio'] > input[type='radio'] + .text, [data-type='radio'] > input[type='checkbox'] + .text,
    [data-type='checkbox'] > input[type='radio'] + .text,
    [data-type='checkbox'] > input[type='checkbox'] + .text {
      display: inline-flex;
      min-height: 1.875rem;
      position: relative;
      word-break: break-word;
      margin-top: 0.9375rem; }
      [data-type='radio'] > input[type='radio'] + .text::before, [data-type='radio'] > input[type='checkbox'] + .text::before,
      [data-type='checkbox'] > input[type='radio'] + .text::before,
      [data-type='checkbox'] > input[type='checkbox'] + .text::before {
        background-color: #fff;
        box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0);
        content: '';
        display: inline-flex;
        margin-right: 0.3125rem;
        position: relative;
        top: -0.46875rem;
        transition: box-shadow 0.3s linear, border 0.3s linear; }
    [data-type='radio'] > input[type='radio']:focus + .text::before, [data-type='radio'] > input[type='checkbox']:focus + .text::before,
    [data-type='checkbox'] > input[type='radio']:focus + .text::before,
    [data-type='checkbox'] > input[type='checkbox']:focus + .text::before {
      box-shadow: 0 0 0 0.125rem rgba(0, 123, 255, 0.25);
      border-color: #9db7d0;
      transition: box-shadow 0.3s linear, border 0.3s linear; }
    [data-type='radio'] > input[type='radio']:checked + .text::after, [data-type='radio'] > input[type='checkbox']:checked + .text::after,
    [data-type='checkbox'] > input[type='radio']:checked + .text::after,
    [data-type='checkbox'] > input[type='checkbox']:checked + .text::after {
      position: absolute; }
    [data-type='radio'] > input[type='radio'][disabled] + .text, [data-type='radio'] > input[type='checkbox'][disabled] + .text,
    [data-type='checkbox'] > input[type='radio'][disabled] + .text,
    [data-type='checkbox'] > input[type='checkbox'][disabled] + .text {
      cursor: no-drop;
      opacity: 0.4; }
      [data-type='radio'] > input[type='radio'][disabled] + .text::before, [data-type='radio'] > input[type='radio'][disabled] + .text::after, [data-type='radio'] > input[type='checkbox'][disabled] + .text::before, [data-type='radio'] > input[type='checkbox'][disabled] + .text::after,
      [data-type='checkbox'] > input[type='radio'][disabled] + .text::before,
      [data-type='checkbox'] > input[type='radio'][disabled] + .text::after,
      [data-type='checkbox'] > input[type='checkbox'][disabled] + .text::before,
      [data-type='checkbox'] > input[type='checkbox'][disabled] + .text::after {
        cursor: no-drop;
        opacity: 0.4; }
  [data-type='radio'] > input[type='checkbox'] + .text::before,
  [data-type='checkbox'] > input[type='checkbox'] + .text::before {
    border-radius: 2px;
    border: 1px solid #9a9a9a;
    height: 30px;
    min-width: 30px;
    width: 30px; }
  [data-type='radio'] > input[type='checkbox']:checked + .text::after,
  [data-type='checkbox'] > input[type='checkbox']:checked + .text::after {
    content: '';
    top: -0.1875rem;
    left: 0.6875rem;
    width: 0.375rem;
    height: 0.9375rem;
    border-right: 0.26786rem solid;
    border-bottom: 0.26786rem solid;
    border-color: #1e6faf;
    transform: rotate(45deg); }
  [data-type='radio'] > input[type='radio'] + .text::before,
  [data-type='checkbox'] > input[type='radio'] + .text::before {
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 100%;
    border: 1px solid #9a9a9a; }
  [data-type='radio'] > input[type='radio']:checked + .text::after,
  [data-type='checkbox'] > input[type='radio']:checked + .text::after {
    content: '';
    top: -0.0625rem;
    left: 0.375rem;
    width: 1.25rem;
    height: 1.25rem;
    background-color: #1e6faf;
    border-radius: 100%; }

.dt-inputForm {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: normal;
  color: #3a3a3a; }
  .dt-inputForm .dt-inputForm-block {
    position: relative;
    margin-right: 0.625rem;
    margin-bottom: 0.625rem; }
  .dt-inputForm .dt-inputText {
    align-items: center;
    display: inline-flex;
    position: relative;
    text-transform: capitalize;
    white-space: nowrap;
    font-weight: bold;
    margin-bottom: 4px; }
    .dt-inputForm .dt-inputText::after {
      content: ':';
      display: inline-block;
      flex-basis: 10px;
      min-width: 10px;
      text-align: center; }
  .dt-inputForm .dt-inputField {
    margin-bottom: 0; }
  .dt-inputForm .dt-inputMessage {
    color: #ce5f54;
    font-size: .7em;
    margin-top: 5px; }
    .dt-inputForm .dt-inputMessage.message-success {
      color: #1ca131; }
  .dt-inputForm .dt-inputDescription {
    font-size: .8em;
    margin-top: 5px;
    font-weight: bold; }
    .dt-inputForm .dt-inputDescription.blink {
      -webkit-animation: blink 2.1s linear 1;
      -moz-animation: blink 2.1s linear 1;
      -ms-animation: blink 2.1s linear 1;
      -o-animation: blink 2.1s linear 1;
      animation: blink 2.1s linear 1; }
    .dt-inputForm .dt-inputDescription .message-success {
      color: #1ca131; }
    .dt-inputForm .dt-inputDescription .message-error {
      color: #ce5f54; }
  .dt-inputForm.--oneBlock .dt-inputForm-block {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    margin-right: 0; }
  .dt-inputForm.--oneBlock .dt-inputText::after {
    content: ''; }
  .dt-inputForm.--oneBlock .dt-inputMessage {
    height: 0;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: height 0.3s linear, overflow 0.3s linear; }
    .dt-inputForm.--oneBlock .dt-inputMessage.--show {
      height: 1rem;
      transition: height 0.3s linear, overflow 0.3s linear; }

.dt-inputForm-block-fix-width-25 {
  flex-basis: unset !important;
  width: 25%;
  margin-right: 5px !important; }

.dt-inputForm-block-fix-width-75 {
  flex-basis: unset !important;
  flex: 1; }

.dt-inputForm-block-fix-width-100 {
  flex-basis: unset !important;
  width: 100%; }

@-webkit-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  50.01% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-moz-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  50.01% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-ms-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  50.01% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-o-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  50.01% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  50.01% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.dt-select, .ui-datepicker .ui-datepicker-header .ui-datepicker-title select {
  position: relative;
  position: relative;
  align-items: baseline;
  background: #f8f8f8;
  border-radius: 0.125rem;
  border: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), inset 0 -20px 0 rgba(0, 0, 0, 0.05);
  color: #000;
  display: inline-flex;
  font-size: initial;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem;
  text-align: center;
  min-width: 5rem;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0; }
  .dt-select[disabled], .ui-datepicker .ui-datepicker-header .ui-datepicker-title select[disabled], .dt-select.disabled, .ui-datepicker .ui-datepicker-header .ui-datepicker-title select.disabled {
    background: whitesmoke;
    color: rgba(0, 0, 0, 0.35);
    box-shadow: none; }
    .dt-select[disabled]::before, .ui-datepicker .ui-datepicker-header .ui-datepicker-title select[disabled]::before, .dt-select[disabled]::after, .ui-datepicker .ui-datepicker-header .ui-datepicker-title select[disabled]::after, .dt-select.disabled::before, .ui-datepicker .ui-datepicker-header .ui-datepicker-title select.disabled::before, .dt-select.disabled::after, .ui-datepicker .ui-datepicker-header .ui-datepicker-title select.disabled::after {
      color: rgba(0, 0, 0, 0.35); }
  .dt-select:active, .ui-datepicker .ui-datepicker-header .ui-datepicker-title select:active {
    box-shadow: 0 0px 0px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 rgba(255, 255, 255, 0), inset 0 1px 1px rgba(0, 0, 0, 0.1); }
  .dt-select::after, .ui-datepicker .ui-datepicker-header .ui-datepicker-title select::after {
    content: "\e906";
    font-family: "dtIconFont-linear";
    color: #2f679e;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
    position: absolute;
    right: 5px; }
  .dt-select > select, .ui-datepicker .ui-datepicker-header .ui-datepicker-title select > select {
    cursor: pointer;
    appearance: button;
    background: none;
    border: 0px;
    padding: 5px;
    padding-right: 2rem;
    width: auto;
    position: relative;
    z-index: 1;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.dt-select-fix {
  background: none;
  box-shadow: none; }

.dt-card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  padding: 1.25rem;
  border-radius: 2px; }
  .dt-card .dt-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 0.625rem;
    margin-bottom: 1.25rem; }

.dt-tableViewList th,
.dt-tableViewList td {
  padding: 0.625rem; }
  .dt-tableViewList th .token-text,
  .dt-tableViewList td .token-text {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.dt-tableViewList thead,
.dt-tableViewList tfoot {
  font-weight: bold; }
  .dt-tableViewList thead tr th,
  .dt-tableViewList tfoot tr th {
    font-size: 1.05rem;
    text-align: left;
    text-transform: capitalize;
    padding: 0.78125rem 0.625rem; }

.dt-tableViewList thead tr:last-of-type {
  border-bottom: 2px solid #1e6faf; }

.dt-tableViewList tfoot tr:first-of-type {
  border-top: 2px solid #1e6faf; }

.dt-tableViewList tbody tr td {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 0.9375rem; }
  .dt-tableViewList tbody tr td:first-of-type {
    border-left: 0px; }
  .dt-tableViewList tbody tr td:last-of-type {
    border-right: 0px; }
  .dt-tableViewList tbody tr td.dt-tableViewList-action {
    padding: 0.3125rem;
    white-space: nowrap;
    width: 0.3125rem; }

.dt-tableViewList tbody tr:nth-child(even) td {
  background-color: #f9f9f9; }

.dt-tableViewList.--lastAction tbody tr td {
  padding-top: 1.25rem; }
  .dt-tableViewList.--lastAction tbody tr td:last-of-type {
    padding: 0.3125rem;
    white-space: nowrap;
    width: 0.3125rem; }

.dt-tableViewList.--widthMax {
  width: 100%; }

.dt-tableViewList.--tdPointer tbody td {
  cursor: pointer; }
  .dt-tableViewList.--tdPointer tbody td::before {
    content: '';
    border: 0px;
    position: absolute;
    z-index: 1;
    top: 0rem;
    box-sizing: border-box;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.2rem solid;
    border-color: rgba(255, 255, 255, 0);
    border-left: 0px;
    border-right: 0px; }
  .dt-tableViewList.--tdPointer tbody td:first-of-type::before {
    border-radius: 0.125rem 0 0 0.125rem;
    border-left: 0.2rem solid;
    border-color: rgba(255, 255, 255, 0); }
  .dt-tableViewList.--tdPointer tbody td:last-of-type::before {
    border-radius: 0 0.125rem 0.125rem 0;
    border-right: 0.2rem solid;
    border-color: rgba(255, 255, 255, 0); }

.dt-tableViewList.--tdPointer tbody tr:hover td {
  position: relative; }
  .dt-tableViewList.--tdPointer tbody tr:hover td::before {
    border-color: rgba(0, 123, 255, 0.25); }
  .dt-tableViewList.--tdPointer tbody tr:hover td:first-of-type::before {
    border-color: rgba(0, 123, 255, 0.25); }
  .dt-tableViewList.--tdPointer tbody tr:hover td:last-of-type::before {
    border-color: rgba(0, 123, 255, 0.25); }

.dt-tableViewList.--uiResponsive {
  display: flex; }
  .dt-tableViewList.--uiResponsive tbody,
  .dt-tableViewList.--uiResponsive thead,
  .dt-tableViewList.--uiResponsive tfoot,
  .dt-tableViewList.--uiResponsive tr {
    display: flex; }
  .dt-tableViewList.--uiResponsive td, .dt-tableViewList.--uiResponsive th {
    border: none; }

.dt-viewInfo {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: normal;
  color: #3a3a3a; }
  .dt-viewInfo .dt-viewInfo-block {
    display: flex;
    margin-bottom: 0.625rem;
    margin-right: 0.625rem;
    position: relative; }
  .dt-viewInfo .dt-viewInfo-title {
    align-items: center;
    display: inline-flex;
    position: relative;
    text-transform: capitalize;
    white-space: nowrap;
    font-weight: bold; }
    .dt-viewInfo .dt-viewInfo-title::after {
      content: ':';
      margin-left: auto;
      display: inline-block;
      flex-basis: 10px;
      min-width: 10px;
      text-align: center; }
    .dt-viewInfo .dt-viewInfo-title[class*="ico-"]::before {
      font-size: 1.2rem;
      margin-right: 0.625rem; }
    .dt-viewInfo .dt-viewInfo-title[class*="ico-"] + .dt-viewInfo-text {
      line-height: 1.2rem; }
  .dt-viewInfo.--oneBlock .dt-viewInfo-block {
    flex-basis: 100%; }

.btnControl {
  align-items: center;
  display: flex;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  padding: 10px; }
  .btnControl .dt-btn {
    margin-right: 0.625rem; }
  .btnControl-left {
    margin-right: auto; }
  .btnControl-center {
    margin-right: auto;
    margin-left: auto; }
  .btnControl-right {
    margin-left: auto; }
  .btnControl.--footer {
    padding: 0.625rem;
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1); }

.ui-widget {
  font-size: 1rem;
  font-family: sans-serif; }

.dt-btn.ui-button .ui-button-text {
  padding: 0; }

.ui-buttonset .ui-state-default {
  align-items: baseline;
  background: #f1f1f1;
  border-radius: 0.125rem;
  border: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), inset 0 -20px 0 rgba(0, 0, 0, 0.05);
  color: #000;
  display: inline-flex;
  font-size: initial;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem;
  text-align: center;
  min-width: 5rem;
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 0;
  margin-right: -1px; }
  .ui-buttonset .ui-state-default[disabled], .ui-buttonset .ui-state-default.disabled {
    background: whitesmoke;
    color: rgba(0, 0, 0, 0.35);
    box-shadow: none; }
    .ui-buttonset .ui-state-default[disabled]::before, .ui-buttonset .ui-state-default[disabled]::after, .ui-buttonset .ui-state-default.disabled::before, .ui-buttonset .ui-state-default.disabled::after {
      color: rgba(0, 0, 0, 0.35); }
  .ui-buttonset .ui-state-default:active {
    box-shadow: 0 0px 0px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 rgba(255, 255, 255, 0), inset 0 1px 1px rgba(0, 0, 0, 0.1); }
  .ui-buttonset .ui-state-default:first-of-type {
    border-radius: 0.125rem 0 0 0.125rem; }
  .ui-buttonset .ui-state-default:last-of-type {
    border-radius: 0 0.125rem 0.125rem 0; }
  .ui-buttonset .ui-state-default.ui-state-active {
    color: #fff;
    background-color: #1e6faf;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.3); }

.ui-buttonset .ui-button-text-only .ui-button-text {
  padding: 0; }

.ui-datepicker {
  background: #fff;
  border-radius: 2px;
  border: 1px solid #b9bfc5;
  box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.1);
  margin-top: 0.1875rem; }
  .ui-datepicker .ui-datepicker-header {
    background: none;
    border-radius: 0;
    border: none;
    display: flex;
    position: relative; }
    .ui-datepicker .ui-datepicker-header a {
      cursor: pointer;
      height: 0.9375rem;
      width: 0.9375rem;
      border-radius: 0;
      top: 50%;
      margin-top: -0.46875rem;
      border-left: 3px solid rgba(0, 0, 0, 0.5);
      border-top: 3px solid rgba(0, 0, 0, 0.5);
      color: #2c2c2c; }
      .ui-datepicker .ui-datepicker-header a:hover {
        background: none;
        border: none;
        border-left: 3px solid rgba(0, 0, 0, 0.5);
        border-top: 3px solid rgba(0, 0, 0, 0.5); }
      .ui-datepicker .ui-datepicker-header a.ui-datepicker-prev {
        left: 12px;
        transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg); }
        .ui-datepicker .ui-datepicker-header a.ui-datepicker-prev:hover {
          left: 12px; }
      .ui-datepicker .ui-datepicker-header a.ui-datepicker-next {
        right: 12px;
        transform: rotate(135deg);
        -o-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -webkit-transform: rotate(135deg); }
        .ui-datepicker .ui-datepicker-header a.ui-datepicker-next:hover {
          right: 12px; }
      .ui-datepicker .ui-datepicker-header a span {
        display: none; }
    .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%; }
      .ui-datepicker .ui-datepicker-header .ui-datepicker-title select {
        font-family: sans-serif;
        color: #3a3a3a;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        text-transform: none;
        margin: 0 0.3125rem; }
  .ui-datepicker .ui-datepicker-calendar {
    box-sizing: border-box; }
    .ui-datepicker .ui-datepicker-calendar th,
    .ui-datepicker .ui-datepicker-calendar td {
      height: 2.1875rem;
      width: 2.1875rem; }
    .ui-datepicker .ui-datepicker-calendar thead tr th {
      font-weight: bold;
      padding: 0.3125rem 0; }
    .ui-datepicker .ui-datepicker-calendar tbody tr td {
      padding: 1px; }
      .ui-datepicker .ui-datepicker-calendar tbody tr td a,
      .ui-datepicker .ui-datepicker-calendar tbody tr td span {
        background-color: #fff;
        background: none;
        font-weight: normal;
        height: 100%;
        padding: 0.3125rem;
        text-align: center;
        width: 100%; }
      .ui-datepicker .ui-datepicker-calendar tbody tr td a {
        align-items: center;
        border-radius: 2px;
        display: flex;
        justify-content: center; }
        .ui-datepicker .ui-datepicker-calendar tbody tr td a.ui-state-default {
          border: 1px solid rgba(255, 255, 255, 0);
          color: #2c2c2c; }
        .ui-datepicker .ui-datepicker-calendar tbody tr td a.ui-state-highlight, .ui-datepicker .ui-datepicker-calendar tbody tr td a.ui-state-active {
          color: #fff;
          font-weight: bold;
          border: 1px solid rgba(0, 0, 0, 0.1); }
        .ui-datepicker .ui-datepicker-calendar tbody tr td a.ui-state-highlight {
          background-color: #3a7db4; }
        .ui-datepicker .ui-datepicker-calendar tbody tr td a.ui-state-active {
          background-color: #1ca131; }
      .ui-datepicker .ui-datepicker-calendar tbody tr td.ui-state-disabled .ui-state-default {
        border: none;
        color: rgba(0, 0, 0, 0.5); }

.ui-widget.ui-dialog {
  background: none;
  border-radius: 0.125rem;
  border: 0px;
  box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.1);
  padding: 0; }
  .ui-widget.ui-dialog .ui-dialog-titlebar {
    border-radius: 0;
    border-radius: 0;
    display: flex;
    background-image: none;
    border: 0px;
    padding: 0;
    height: 2.8125rem;
    align-items: center;
    background-color: #1e6faf;
    border: 1px solid #1a6199;
    border-radius: 0.125rem 0.125rem 0 0; }
    .ui-widget.ui-dialog .ui-dialog-titlebar .ui-dialog-title {
      flex: 1;
      float: initial;
      padding: 0.625rem; }
    .ui-widget.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close {
      align-items: center;
      background-color: #1e6faf;
      background-image: initial;
      border-radius: initial;
      border: initial;
      display: flex;
      height: 100%;
      justify-content: center;
      margin: initial;
      padding: initial;
      position: relative;
      right: initial;
      top: initial;
      width: 2.8125rem; }
      .ui-widget.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close::before {
        font-family: "dtIconFont-linear";
        color: #2f679e;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        text-transform: none;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.5rem;
        color: rgba(0, 0, 0, 0.25);
        transition: color 0.3s linear; }
      .ui-widget.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close:hover::before {
        color: rgba(255, 255, 255, 0.75);
        transition: color 0.3s linear; }
      .ui-widget.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close > span {
        display: none; }
  .ui-widget.ui-dialog .ui-dialog-content {
    background-color: #fff;
    border-radius: 0 0 0.125rem 0.125rem; }

.ui-widget.ui-tabs {
  border: 0px;
  background: none;
  border-radius: 2px; }
  .ui-widget.ui-tabs .ui-tabs-nav {
    border-radius: 2px 2px 0 0;
    background: #e7e7e7;
    border: 1px solid rgba(0, 0, 0, 0.2); }
    .ui-widget.ui-tabs .ui-tabs-nav .ui-state-default {
      background: none;
      border: none; }
      .ui-widget.ui-tabs .ui-tabs-nav .ui-state-default a {
        color: #3a3a3a; }
      .ui-widget.ui-tabs .ui-tabs-nav .ui-state-default.ui-state-active {
        background-color: #fff;
        border-radius: 2px 2px 0 0;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-bottom: 0;
        font-weight: bold; }
  .ui-widget.ui-tabs .ui-tabs-panel {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-top: 0;
    border-radius: 0 0 2px 2px; }

.customReactResponsiveModal {
  border-radius: 0.125rem;
  font-size: 1rem;
  min-width: 18.75rem;
  padding: 0.625rem; }
  .customReactResponsiveModal .modalHead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 1.2em;
    font-weight: bold;
    padding: 0.625rem; }
  .customReactResponsiveModal .modalContent {
    line-height: normal;
    min-height: 6.25rem;
    padding: 0.625rem; }
  .customReactResponsiveModal .modalContent-title {
    font-weight: bold; }
  .customReactResponsiveModal .modalFooter {
    align-items: center;
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    margin-top: 10px;
    padding: 10px; }
    .customReactResponsiveModal .modalFooter-left {
      margin-right: auto; }
    .customReactResponsiveModal .modalFooter-center {
      margin-right: auto;
      margin-left: auto; }
    .customReactResponsiveModal .modalFooter-right {
      margin-left: auto; }
  .customReactResponsiveModal .dt-btn {
    margin-left: 5px; }

.react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%; }
    .react-datepicker-wrapper .react-datepicker__input-container button {
      width: 100%; }
    .react-datepicker-wrapper .react-datepicker__input-container i {
      position: absolute;
      right: 5px;
      top: 8px; }

.ui.fluid.dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px;
  border-color: #b8bfc5;
  border-radius: 2px;
  border-style: solid;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  height: 2.5rem;
  padding: 0.3125rem;
  transition: border 0.3s linear, box-shadow 0.3s linear; }

/** Components */
body {
  background: radial-gradient(#e5e5e5 13%, transparent 16%);
  background-color: #f9f9f9;
  background-size: 5px 5px; }

.mainMenuHead i,
.mainMenuList i {
  cursor: pointer;
  margin-right: 0.625rem; }
  .mainMenuHead i[class*="ico-"]::before,
  .mainMenuList i[class*="ico-"]::before {
    font-size: 1.6rem; }

.mainMenuHead {
  background-color: #f3f5f8;
  margin-bottom: 0.625rem;
  padding: 0.625rem 0.9375rem;
  height: 45px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold; }

.mainMenuList {
  padding-bottom: 0.625rem;
  height: calc(100vh - 165px);
  overflow: auto; }

.mainMenuList-item {
  padding: 0.625rem 0.9375rem;
  text-transform: capitalize;
  font-size: .9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1; }
  .mainMenuList-item::after {
    content: '';
    position: absolute;
    height: 100%;
    width: calc(100% - 0.625rem);
    left: 0.3125rem;
    border-radius: 0.125rem;
    background-color: rgba(255, 255, 255, 0);
    z-index: -1; }
  .mainMenuList-item:hover::after {
    background-color: rgba(0, 0, 0, 0.05); }
  .mainMenuList-item.--active {
    color: #fff; }
    .mainMenuList-item.--active::after {
      background-color: #1e6faf; }

.mainMenuList-fix {
  height: auto; }

.mainMenuList-title {
  font-size: 1.1rem;
  font-weight: bold;
  padding-left: 0.3125rem;
  padding-bottom: 0.3125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

@media screen and (max-width: 1024px) {
  .mainMenuHead > span, .mainMenuList-item > span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

.nhsoTokenForm,
.columnForm {
  width: 100%;
  flex-wrap: nowrap;
  align-items: center; }
  .nhsoTokenForm .dt-inputForm-block,
  .columnForm .dt-inputForm-block {
    flex: 1; }
    .nhsoTokenForm .dt-inputForm-block:first-child,
    .columnForm .dt-inputForm-block:first-child {
      margin-right: 5px; }
  .nhsoTokenForm .btnAction,
  .columnForm .btnAction {
    margin-left: 0.625rem;
    margin-top: 0.4375rem; }

.tokenCard {
  background-color: #f8f8f8;
  border-radius: 0.125rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-bottom: 0.625rem;
  position: relative;
  min-height: 45px; }
  .tokenCard .tokenCard-text {
    flex: 1;
    padding: 0.625rem;
    padding-top: 0.9375rem;
    word-break: break-word; }
  .tokenCard .tokenCard-action .dt-btn {
    border-radius: 0;
    border: 0px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: none;
    height: 100%; }
  .tokenCard.--expiry {
    margin-left: 5px; }
    .tokenCard.--expiry::before {
      content: '';
      border-radius: 0.125rem 0 0 0.125rem;
      width: 5px;
      height: calc(100% + 2px);
      background-color: #ce5f54;
      position: absolute;
      left: -5px;
      top: -1px; }

.userProfileContent {
  display: flex; }
  .userProfileContent .profileImage,
  .userProfileContent .profileInfo {
    margin: 0 auto;
    display: inline-flex;
    flex-direction: column; }
  .userProfileContent .profileImage {
    margin-right: 0.625rem;
    position: relative; }
  .userProfileContent .profileInfo .dt-viewInfo {
    margin-bottom: auto; }
    .userProfileContent .profileInfo .dt-viewInfo .dt-viewInfo-title {
      width: 6.75rem; }
  .userProfileContent .profileInfo .btnControl {
    margin-top: auto; }

.profileImage-content {
  align-items: center;
  align-items: center;
  background-image: url("../images/icons/userProfile.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  height: 9.375rem;
  justify-content: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.3125rem;
  width: 9.375rem; }
  .profileImage-content img {
    height: 100%;
    width: auto;
    max-height: 100%;
    max-width: 100%; }

.profileImage-upLoad {
  position: absolute;
  bottom: 0.625rem;
  left: 0.625rem;
  width: calc(100% - rem(20px)); }
  .profileImage-upLoad .dt-inputFile {
    width: 100%; }

@media screen and (max-width: 650px) {
  .userProfileContent {
    flex-direction: column; }
    .userProfileContent .profileImage {
      margin: initial;
      margin-right: auto;
      margin-bottom: 0.625rem; }
    .userProfileContent .profileInfo {
      display: inline-block; } }

.userManagementTable .dt-tableView-action {
  width: 5px;
  white-space: nowrap; }

.activeForm-block {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem; }

.licenseBar {
  width: 100%;
  text-align: center;
  font-weight: bold; }
  .licenseBar.active {
    color: #1ca131; }
  .licenseBar.warning {
    color: #FFC107; }
  .licenseBar.expire {
    color: #ce5f54; }

/** Themes */
* {
  outline: none; }

html {
  font-size: 16px; }

body {
  font-family: sans-serif;
  color: #3a3a3a;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-transform: none; }

textarea {
  min-height: 96px;
  width: 100% !important; }

.textBold {
  font-weight: bold; }

.mainContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100vh; }

.mainHeader {
  background-color: #f7f7f7;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-wrap: wrap;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 3.75rem;
  min-height: 3.75rem;
  max-height: 3.75rem; }
  .mainHeader > * {
    display: inline-flex;
    height: 100%; }
  .mainHeader::before, .mainHeader::after {
    content: '';
    width: calc((100% - 64rem) / 2); }

.mainHeader-logo {
  align-items: center;
  justify-content: center;
  margin-right: auto;
  font-size: 18px;
  font-weight: bold; }
  .mainHeader-logo img {
    display: inline-flex;
    height: 3.125rem; }

.mainHeader-logout .btnLogout {
  align-items: center;
  background: none;
  border: 0px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  height: 3.75rem;
  justify-content: center;
  width: 3.75rem; }
  .mainHeader-logout .btnLogout::before {
    font-size: 1.7rem; }

.mainContent {
  display: flex;
  flex: 1;
  margin: 0 auto;
  max-width: 100%;
  overflow: auto;
  padding: 1.25rem;
  position: relative;
  width: 1064px; }

.projectVer {
  color: #525252;
  font-size: .7rem;
  letter-spacing: 0.0625rem; }
  .projectVer::before {
    content: 'Ver.';
    margin-right: 0.3125rem; }

.mainContent-navBar,
.mainContent-showInfo {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  min-height: calc(100vh - 6.25rem); }

.mainContent-navBar {
  width: 15.625rem;
  margin-right: 0.625rem;
  background-color: #fff;
  border-radius: 0.125rem; }

.mainContent-showInfo {
  border-radius: 0.125rem;
  background-color: #fff;
  height: 100%;
  flex: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  padding: 0.625rem; }

.showInfoHead {
  display: flex;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 0.625rem;
  align-items: center;
  height: 3.125rem; }
  .showInfoHead .showInfoHead-title {
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: capitalize;
    flex: 1; }
    .showInfoHead .showInfoHead-title.showButtonHead {
      text-align: right;
      padding-right: 10px; }

.showInfoContent {
  height: calc(100vh - 165px);
  overflow: auto;
  padding: 0.625rem; }
  .showInfoContent.--privilege {
    padding: initial;
    padding-top: 0.625rem; }

.privilegeContent {
  display: flex; }
  .privilegeContent .dt-card.privilegeCard {
    user-select: none;
    padding: 0.625rem;
    margin-bottom: 0.625rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #f8f8f8;
    cursor: move; }
    .privilegeContent .dt-card.privilegeCard .privilegeCard-title {
      display: inline-flex;
      font-weight: bold;
      text-transform: capitalize;
      width: 3.125rem; }
      .privilegeContent .dt-card.privilegeCard .privilegeCard-title::after {
        content: ':';
        display: inline-block;
        margin-left: auto;
        text-align: center;
        width: 0.625rem; }

.privilegeList,
.privilegeNewList {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.125rem;
  flex: 1; }

.privilegeList-search {
  padding: 0.625rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1; }
  .privilegeList-search .dt-inputField {
    width: 100%; }

.privilegeAction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.625rem; }
  .privilegeAction[class*='ico-']::before {
    font-size: 2.8rem; }
  .privilegeAction > .dt-btn {
    margin: 0.625rem; }

.privilegeNewList {
  display: flex;
  flex-direction: column; }

.privilege-btnControl {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.625rem; }

.privilege-dragContent,
.privilege-dropContent {
  padding: 0.625rem;
  height: calc(100vh - 250px);
  overflow: auto;
  -webkit-overflow-scrolling: auto; }
  .privilege-dragContent > *,
  .privilege-dropContent > * {
    min-height: 100%; }

.privilege-dropContent {
  flex: 1; }

.changePasswordForm,
.mfuConnectorForm,
.basicForm {
  width: 100%;
  max-width: 25rem;
  margin: auto; }

.PZODQ {
  line-height: 1.3rem; }

.ggxIoG,
.bJVMLJ {
  background-color: #67aee5;
  color: #13466e; }

.mainContent-navBar-fix {
  display: flex;
  flex-direction: column; }

.applicationContent-bar {
  margin-top: auto;
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px; }

.formTitle {
  width: 100%;
  font-size: 1.1rem;
  margin: 10px 0;
  font-weight: bold; }

.urlField {
  display: table; }
  .urlField .dt-inputText {
    display: table-cell;
    text-transform: none; }
  .urlField .dt-input-fix {
    width: 100%; }

.fixWidth {
  width: 5px;
  white-space: nowrap;
  text-align: center; }

.circle-active {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 auto; }
  .circle-active.--active {
    background-color: #1ca131; }
  .circle-active.--notactive {
    background-color: #ea3f2e; }

.copy-active {
  background-color: #cce2fe; }

.loading-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center; }
  .loading-box .loading {
    display: flex;
    width: 74px;
    justify-content: space-between; }

@media screen and (max-width: 1024px) {
  .mainContent-navBar {
    width: auto;
    max-width: 12.5rem; } }

@media screen and (max-width: 850px) {
  .mainContent-navBar {
    max-width: 3.625rem;
    overflow: hidden;
    position: absolute;
    z-index: 4;
    transition: max-width 0.1s linear; }
  .mainContent-showInfo {
    margin-left: 4.25rem; }
  .mainContent {
    position: relative; }
    .mainContent::before {
      content: '';
      background-color: rgba(193, 193, 193, 0);
      border-radius: 0.125rem;
      position: absolute;
      z-index: 3;
      transition: background-color 0.3s linear; }
    .mainContent.--MenuExpand .mainContent-navBar {
      max-width: 100%;
      transition: max-width 1s linear; }
    .mainContent.--MenuExpand::before {
      background-color: rgba(193, 193, 193, 0.85);
      height: calc(100% - 2.5rem);
      width: calc(100% - 2.5rem); }
  .applicationContent-bar {
    display: none; } }

@media screen and (max-width: 750px) {
  .privilegeContent {
    flex-direction: column; }
    .privilegeContent .privilege-dragContent,
    .privilegeContent .privilege-dropContent {
      max-height: calc((100vh - 345px) / 2); }
    .privilegeContent .privilegeAction {
      overflow: hidden;
      padding: 0;
      text-align: center; }
      .privilegeContent .privilegeAction::before {
        transform: rotate(90deg);
        font-size: 2.3rem; } }

@media screen and (max-width: 650px) {
  .mainContent-navBar {
    max-width: initial;
    min-height: inherit;
    width: calc(100% - 1.25rem);
    max-height: 2.8125rem;
    height: 2.8125rem;
    overflow: hidden;
    position: absolute;
    transition: all 0.5s linear; }
  .mainContent-showInfo {
    margin-left: 0;
    min-height: initial;
    margin-top: 3.4375rem; }
    .mainContent-showInfo .showInfoContent {
      overflow: auto;
      max-height: calc(100vh - 210px);
      -webkit-overflow-scrolling: touch; }
  .mainContent {
    flex-direction: column;
    padding: 0.625rem; }
    .mainContent.--MenuExpand::before {
      width: calc(100% - 1.25rem);
      height: calc(100% - 1.25rem); }
    .mainContent.--MenuExpand .mainContent-navBar {
      height: auto;
      max-height: calc(100vh - 80px);
      z-index: 4;
      transition: all 0.5s linear; }
      .mainContent.--MenuExpand .mainContent-navBar .mainMenuList {
        height: initial;
        max-height: calc(100vh - 157px); }
  .privilegeContent .privilege-dragContent,
  .privilegeContent .privilege-dropContent {
    padding: 0;
    max-height: calc((100vh - 385px) / 2); }
  .mainContent-navBar-fix {
    display: unset; } }

@media screen and (max-width: 600px) {
  .userManagementTable {
    display: flex; }
    .userManagementTable tbody,
    .userManagementTable thead,
    .userManagementTable tfoot,
    .userManagementTable tr {
      display: flex;
      flex-direction: column;
      width: 100%; }
    .userManagementTable td,
    .userManagementTable th {
      width: auto !important;
      line-height: 1.5rem;
      padding: 0 !important;
      border: none !important; }
    .userManagementTable thead {
      display: none; }
    .userManagementTable tr {
      border-radius: 0.125rem;
      border: 1px solid #dedede;
      flex-wrap: wrap;
      margin-bottom: 0.625rem;
      padding: 0.625rem; }
      .userManagementTable tr:last-of-type {
        margin-bottom: 0; }
    .userManagementTable tbody td {
      display: inline-flex;
      justify-content: flex-start;
      position: relative;
      background-color: #fff !important; }
      .userManagementTable tbody td::before, .userManagementTable tbody td::after {
        font-weight: bold; }
      .userManagementTable tbody td::before {
        order: -2;
        flex-basis: 75px;
        text-transform: capitalize; }
      .userManagementTable tbody td::after {
        content: ':';
        order: -1;
        margin-right: 0.3125rem; }
      .userManagementTable tbody td:nth-of-type(1)::before {
        content: 'full name'; }
      .userManagementTable tbody td:nth-of-type(2)::before {
        content: 'username'; }
      .userManagementTable tbody td:nth-of-type(3)::before {
        content: 'e-mail'; }
      .userManagementTable tbody td:nth-of-type(4) {
        flex-basis: calc(100% - 6.25rem); }
        .userManagementTable tbody td:nth-of-type(4)::before {
          content: 'role'; }
      .userManagementTable tbody td:nth-of-type(5) {
        position: absolute;
        right: 0.625rem;
        bottom: 0.625rem; }
        .userManagementTable tbody td:nth-of-type(5)::before, .userManagementTable tbody td:nth-of-type(5)::after {
          content: '';
          display: none; } }
