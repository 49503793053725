// Variable Color
// -------------------------------
$main-body-color: #f9f9f9 !default;
$main-border-color: #dedede !default;
$main-theme-color: #1e6faf !default;
$main-shadow-color--focus:rgba(0, 123, 255, .25) !default;
$main-border-color--focus:#9db7d0 !default;
$main-link-color: $main-theme-color;
// Status
$color--primary:#3a7db4 !default;
$color--success:#1ca131 !default;
$color--disabled:#8d8d8d !default;
$color--error:#ce5f54 !default;
$color--warning:#FFC107 !default;