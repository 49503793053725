// Form
// -------------------------------
.dt-inputForm {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: normal;
  color: $main-font-color;
  .dt-inputForm-block {
    position: relative;
    margin-right: rem($main-space);
    margin-bottom: rem($main-space);
  }
  .dt-inputText {
    align-items: center;
    display: inline-flex;
    position: relative;
    text-transform: capitalize;
    white-space: nowrap;
    font-weight: bold;
    margin-bottom: 4px;
    &::after {
      content: ':';
      display: inline-block;
      flex-basis: 10px;
      min-width: 10px;
      text-align: center;
    }
  }
  .dt-inputField {
    margin-bottom: 0;
  }
  .dt-inputMessage {
    color: $color--error;
    font-size: .7em;
    margin-top: 5px;
    &.message-success {
      color: $color--success;
    }
  }
  .dt-inputDescription {
    font-size: .8em;
    margin-top: 5px;
    font-weight: bold;
    &.blink {
      -webkit-animation: blink 2.1s linear 1;
      -moz-animation: blink 2.1s linear 1;
      -ms-animation: blink 2.1s linear 1;
      -o-animation: blink 2.1s linear 1;
       animation: blink 2.1s linear 1;
    }
    .message-success{
      color: $color--success;
    }
    .message-error{
      color: $color--error;
    }
  }
  &.--oneBlock {
    .dt-inputForm-block {
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      margin-right: 0;
    }
    .dt-inputText {
      &::after {
        content: '';
      }
    }
    .dt-inputMessage {
      height: 0;
      text-align: left;
      @include textDot();
      @include transitionMulti((height, overflow));
      &.--show {
        height: 1rem;
        @include transitionMulti((height, overflow));
      }
    }
  }
}

// fix
.dt-inputForm-block-fix-width-25 {
  flex-basis: unset !important;
  width: 25%;
  margin-right: 5px !important;
}

.dt-inputForm-block-fix-width-75 {
  flex-basis: unset !important;
  // width: 75%;
  flex: 1;
}

.dt-inputForm-block-fix-width-100 {
  flex-basis: unset !important;
  width: 100%;
}

@-webkit-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-moz-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-ms-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-o-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}