// ##### jQuery Datepicker #####
$jq-btn-radius:$main-radius !default;
// --------------------------------------------------------------------
.dt-btn.ui-button {
  .ui-button-text {
    padding: 0;
  }
}

.ui-buttonset {
  .ui-state-default {
    @include btnStyle();
    border-radius: 0;
    margin-right: -1px;
    &:first-of-type {
      border-radius: rem($main-radius) 0 0 rem($main-radius);
    }
    &:last-of-type {
      border-radius: 0 rem($main-radius) rem($main-radius) 0;
    }
    &.ui-state-active {
      color: #fff;
      background-color: $main-theme-color;
      box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.3);
    }
  }
  .ui-button-text-only {
    .ui-button-text {
      padding: 0;
    }
  }
}