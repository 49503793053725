.icoAf-attachFileHoriz:after {
  content: "\e929";
}

.icoAf-attachFileVert:after {
  content: "\e92a";
}

.icoAf-publish:after {
  content: "\e92b";
}

.icoAf-cloudUpload:after {
  content: "\e92c";
}

.icoAf-cloudOff:after {
  content: "\e92d";
}

.icoAf-cloudDownload:after {
  content: "\e92e";
}

.icoAf-cloudDone:after {
  content: "\e92f";
}

.icoAf-cloud:after {
  content: "\e930";
}

.icoAf-folderShared:after {
  content: "\e931";
}

.icoAf-foldeOpen:after {
  content: "\e932";
}

.icoAf-folder:after {
  content: "\e933";
}

.icoAf-createNewFolder:after {
  content: "\e934";
}

.icoAf-insertInvitation:after {
  content: "\e935";
}

.icoAf-wallpaper:after {
  content: "\e936";
}

.icoAf-sort:after {
  content: "\e937";
}

.icoAf-textFormat:after {
  content: "\e938";
}

.icoAf-unarchive:after {
  content: "\e939";
}

.icoAf-moveToInbox:after {
  content: "\e93a";
}

.icoAf-saveAlt:after {
  content: "\e93b";
}

.icoAf-save:after {
  content: "\e93c";
}

.icoAf-menu:after {
  content: "\e93d";
}

.icoAf-search:after {
  content: "\e93e";
}

.icoAf-wifiOff:after {
  content: "\e91d";
}

.icoAf-wifi:after {
  content: "\e91e";
}

.icoAf-power:after {
  content: "\e91f";
}

.icoAf-settingBox:after {
  content: "\e920";
}

.icoAf-settings:after {
  content: "\e921";
}

.icoAf-powerStart:after {
  content: "\e922";
}

.icoAf-bolt:after {
  content: "\e923";
}

.icoAf-people:after {
  content: "\e924";
}

.icoAf-swapVert:after {
  content: "\e925";
}

.icoAf-swapHoriz:after {
  content: "\e926";
}

.icoAf-swapVertCircle:after {
  content: "\e927";
}

.icoAf-swapHorizCircle:after {
  content: "\e928";
}

.icoAf-cancelCircle:after {
  content: "\e902";
}

.icoAf-arrowUp:after {
  content: "\e903";
}

.icoAf-arrowRight:after {
  content: "\e904";
}

.icoAf-arrowLeft:after {
  content: "\e905";
}

.icoAf-arrowDown:after {
  content: "\e906";
}

.icoAf-photo:after {
  content: "\e907";
}

.icoAf-pen:after {
  content: "\e908";
}

.icoAf-block:after {
  content: "\e909";
}

.icoAf-outline-clear-24px:after {
  content: "\e90a";
}

.icoAf-backspace:after {
  content: "\e90b";
}

.icoAf-addCircle:after {
  content: "\e90c";
}

.icoAf-addBox:after {
  content: "\e90d";
}

.icoAf-add:after {
  content: "\e90e";
}

.icoAf-accountBox:after {
  content: "\e90f";
}

.icoAf-renew:after {
  content: "\e910";
}

.icoAf-checkCircle:after {
  content: "\e911";
}

.icoAf-file:after {
  content: "\e912";
}

.icoAf-description:after {
  content: "\e913";
}

.icoAf-trashForever:after {
  content: "\e914";
}

.icoAf-trash:after {
  content: "\e915";
}

.icoAf-notifications:after {
  content: "\e916";
}

.icoAf-notificationsBlocking:after {
  content: "\e917";
}

.icoAf-exit:after {
  content: "\e918";
}

.icoAf-email:after {
  content: "\e919";
}

.icoAf-help:after {
  content: "\e91a";
}

.icoAf-info:after {
  content: "\e91b";
}

.icoAf-key:after {
  content: "\e91c";
}

.icoAf-lock:after {
  content: "\e900";
}

.icoAf-user:after {
  content: "\e901";
}