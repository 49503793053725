// Mixins fixSize
// -------------------------------

@mixin fixHeight($height) {
  height: $height;
  min-height: $height;
  max-height: $height;
}
@mixin fixWidth($height) {
  width: $height;
  min-width: $height;
  max-width: $height;
}

@mixin fixHeight-clear() {
  height: initial;
  min-height: initial;
  max-height: initial;
}

@mixin fixWidth-clear() {
  width: initial;
  min-width: initial;
  max-width: initial;
}